import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const customerSignedInHandler = ({ detail }: EbizioEvent) => {
  logger('Customer Signed In');
  console.log(detail);
};

export const customerSignedInConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_SIGNED_IN,
  handler: customerSignedInHandler,
};
