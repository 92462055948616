import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const customerSignedOutHandler = ({ detail }: EbizioEvent) => {
  logger('Customer Signed Out');
  console.log(detail);
};

export const customerSignedOutConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_SIGNED_OUT,
  handler: customerSignedOutHandler,
};
