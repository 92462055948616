import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';

export const paymentStepSubmittedHandler = ({ detail }: EbizioEvent) => {
  logger('Payment Step Submitted');
  setActiveStepOnBody(detail.activeStepType);
  console.log(detail);
};

export const paymentStepSubmittedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_STEP_SUBMITTED,
  handler: paymentStepSubmittedHandler,
};
