// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0_webpack-cli@4.10.0_webpack-dev-server@4.15.2_webpack@5.91.0__/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0_webpack-cli@4.10.0_webpack-dev-server@4.15.2_webpack@5.91.0__/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0_webpack-cli@4.10.0_webpack-dev-server@4.15.2_webpack@5.91.0__/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add Custom Checkout CSS Here */
[data-active-step='shipping'] .checkout-step--billing,
[data-active-step='shipping'] .checkout-step--customer {
  pointer-events: none;
}

/* #addressToggle,
[data-active-step='shipping'] [data-test='step-edit-button'],
#shippingAddresses,
#checkout-shipping-options,
#checkout-shipping-options ~ .form-actions,
#addressDropdown li,
#billingAddresses,
#checkout-billing-continue,
#checkoutBillingAddress .loadingOverlay-container .form-fieldset + .form-field,
[data-test='checkout-shipping-comments'],
.customerEmail-body .form-field:nth-child(2),
#companyInput-label .optimizedCheckout-contentSecondary {
  display: none;
} */

#addressDropdown li:nth-child(1) {
  display: block;
}

/* [class*='dynamic-form-field--field_'],
[name='shouldSaveAddress'],
[name='shouldSaveAddress'] + label,
[name='shippingAddress.shouldSaveAddress'],
[name='shippingAddress.shouldSaveAddress'] + label,
.checkout-step--payment .checkout-form .form-actions,
.form-field--verify-ff-hide {
  display: none !important;
} */

.dynamic-form-field.dynamic-form-field--savedAddress {
  margin-bottom: 0.75rem !important;
}

.dynamic-form-field--savedAddress .dropdown-chevron {
  top: 12.7px;
}
.fake-shipping-options {
  position: relative;
}

.form-legend--eori {
  padding-bottom: 0;
}

.eori-disclaimer {
  margin-bottom: 0.75rem;
}

#freight-forwarder-disclaimer-scroll {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  height: 200px;
  border-radius: 4px;
  overflow-y: scroll;
  margin-bottom: 1rem;
}

.freight-forwarder-disclaimer,
.eori-disclaimer,
.ship-complete-disclaimer {
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 0.25rem;
}

@media only screen and (min-width: 551px) {
  .fake-shipping-options {
    /* margin-left: 4.19231rem; */
  }
}

.fso-form-field {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
}

.fso-form-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.fso-form-overlay {
  display: none;
}

.fso-form-overlay--show {
  display: block;
}

.soa-form {
  width: 100%;
  padding: 1rem;
  background-color: #f4f4f4;
  border-radius: 4px;
  margin: 1rem 0;
}

.soa-form-field {
  margin-bottom: 0.75rem;
}

.soa-form-field:last-child {
  margin-bottom: 0;
}

.button.button--swal {
  margin-bottom: 0;
}

#fso-purchase-order-input.has-error {
  border-color: red;
}

#fso-purchase-order-input.has-error + span {
  display: inline-block !important;
  color: red;
}

.button--fake-billing {
  margin-left: 0 !important;
}

/* Loading overlay  */

.loadingNotification {
  transform: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10rem;
}

.loadingNotification::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.3); */
  background-color: none;
  pointer-events: none;
}

.optimizedCheckout-loadingToaster {
  z-index: 1;
}

/* Bootstrap Styles */

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

/* .accordion-collapse--hasSelectedOption.collapsing {
    transition: none;
} */

/* .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
} */

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
