import { fillInShippingInformation } from '../custom/customer-group-shipping-address';
import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const useNewShippingAddressSelectedHandler = (event: EbizioEvent) => {
  logger('Use New Shipping Address Selected');
  console.log(event.detail);
};

export const useNewShippingAddressSelectedConfig: EbizioEventConfig = {

  event: EBIZIO_EVENT_TYPE.USE_NEW_SHIPPING_ADDRESS_SELECTED,
  handler: async (event) => {
      logger('Use New Shipping Address Selected');
      console.log(event.detail);

      await fillInShippingInformation()

  },
};