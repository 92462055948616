import jwt_decode from 'jwt-decode';

let globalTokenPromise: Promise<string> | null = null;
/**
 * get token
 */
export async function getToken() {
    if (globalTokenPromise) {
        const result = await globalTokenPromise;

        if (tokenIsValid(result)) {
            return result;
        }
    }

    const result = await (globalTokenPromise = getNewToken());

    return result;
}


interface CustomerToken {
    exp: number;
}

/**
 * validate token
 */
const tokenIsValid = (token: string) => {
    if (!token) {
        return false;
    }
    const decoded = jwt_decode<CustomerToken>(token);
    return decoded.exp >= Math.floor(Date.now() / 1000) - 10;
};


/**
 * get jwt token
 * - this method is only called when no token is in global state or the token in global lstate is invalid
 */
export const getNewToken = async (): Promise<string> => {
    // setup variables
    const prod = '3oitq0b8vnxvofjggo30rpm0qo4bmo0'
    const dev = 'fafqxr4yr7jgnjfbf3fwn09r4d83dpk'
    const staging = 'eqlrfd8hx19joyzffvlg2kd1s3ctkdz'
    const clientId =  staging;
    const url = `/customer/current.jwt?app_client_id=${clientId}`;

    // fetch token
    try {
        const res = await fetch(url)
        const data = await res.text();
        return String(data);
    } catch (error) {
        console.error(error);
        throw error;
    }
};
