import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const checkoutLoadedHandler = ({ detail }: EbizioEvent) => {
  logger('Checkout Loaded');
  console.log(detail);
};

export const checkoutLoadedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CHECKOUT_LOADED,
  handler: checkoutLoadedHandler,
};
