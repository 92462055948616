import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const paymentMethodSelectedHandler = async ({ detail }: EbizioEvent) => {
  logger('Payment Method Selected');
  console.log(detail);
};

export const paymentMethodSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_METHOD_SELECTED,
  handler: paymentMethodSelectedHandler,
};
