import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryDesktopMountedHandler = ({ detail }: EbizioEvent) => {
  logger('Cart Summary Desktop Mounted');
  console.log(detail);
};

export const cartSummaryDesktopMountedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_DESKTOP_MOUNTED,
  handler: cartSummaryDesktopMountedHandler,
};
