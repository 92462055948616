const checkoutId = window.EbizioEventManager.checkoutId;

const lsKeys = {
  shipComplete: 'eem-shipComplete',
  carrier: 'eem-carrier',
  method: 'eem-method',
  accountNumber: 'eem-accountNumber',
  poNumber: 'eem-poNumber',
  orderComments: 'eem-orderComments',
  eoriNumber: 'eem-eoriNumber',
  verifyFF: 'eem-verifyFF',
};

export const localStorageCleanUp = () => {
  const keys = Object.values(lsKeys);
  keys.map((key) => {
    const lsItem = getLocalStorageItem(key);
    if (!lsItem || !checkoutId) return;
    if (lsItem[0] !== checkoutId) {
      removeLocalStorageItem(key);
    }
  });
};

export const getLocalStorageItem = (key: string) => {
  const data = localStorage.getItem(key);

  if (data) {
    return JSON.parse(data);
  }

  return null;
};

export const removeLocalStorageItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const setShipComplete = (value: string) => {
  localStorage.setItem(lsKeys.shipComplete, `["${checkoutId}", "${value}"]`);
};

export const getShipComplete = () => {
  const data = localStorage.getItem(lsKeys.shipComplete);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export const setVerifyFF = (value: string) => {
  localStorage.setItem(lsKeys.verifyFF, `["${checkoutId}", "${value}"]`);
};

export const getVerifyFF = () => {
  const data = localStorage.getItem(lsKeys.verifyFF);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export const setOrderComments = (value: string) => {
  localStorage.setItem(lsKeys.orderComments, `["${checkoutId}", "${value}"]`);
};

export const getOrderComments = () => {
  const data = localStorage.getItem(lsKeys.orderComments);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export const setPoNumber = (value: string) => {
  localStorage.setItem(lsKeys.poNumber, `["${checkoutId}", "${value}"]`);
};

export const getPoNumber = () => {
  const data = localStorage.getItem(lsKeys.poNumber);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export const setEoriNumber = (value: string) => {
  localStorage.setItem(lsKeys.eoriNumber, `["${checkoutId}", "${value}"]`);
};

export const getEoriNumber = () => {
  const data = localStorage.getItem(lsKeys.eoriNumber);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export interface SOAField {
  consignmentId: string;
  carrier: string;
  method: string;
  accountNumber: string;
}

export const setCarrier = (value: string) => {
  localStorage.setItem(lsKeys.carrier, `["${checkoutId}", "${value}"]`);
};

export const getCarrier = () => {
  const data = localStorage.getItem(lsKeys.carrier);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export const setMethod = (value: string) => {
  localStorage.setItem(lsKeys.method, `["${checkoutId}", "${value}"]`);
};

export const getMethod = () => {
  const data = localStorage.getItem(lsKeys.method);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};

export const setAccountNumber = (value: string) => {
  localStorage.setItem(lsKeys.accountNumber, `["${checkoutId}", "${value}"]`);
};

export const getAccountNumber = () => {
  const data = localStorage.getItem(lsKeys.accountNumber);

  if (data) {
    return JSON.parse(data)[1];
  }

  return null;
};
