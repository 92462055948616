/**
 * Helper function to simulate clicking a link or button (ie. move customer back to previous step or something)
 */
export const simulateClick = (selector: string) => {
  const element = document.querySelector(selector);
  if (element) {
    if (typeof Event === 'function') {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
      });
      element.dispatchEvent(clickEvent);
    }
  }
};
