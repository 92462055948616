import { simulateClick } from '../../utils';

export const handleSavedAddress = () => {
  let i1: NodeJS.Timer;
  let i2: NodeJS.Timer;

  let i2count = 0;

  i1 = setInterval(() => {
    const el1 = document.getElementById('addressToggle');

    if (el1) {
      clearInterval(i1);

      if (el1.textContent === 'Enter a new address') return;

      simulateClick('#addressToggle');
      i2 = setInterval(function () {
        const el2 = document.getElementById('addressDropdown');

        if (el2) {
          clearInterval(i2);
          return simulateClick('#addressDropdown li:nth-child(1) a');
        }

        if (i2count < 5) {
          clearInterval(i2);
          handleSavedAddress();
        }

        i2count++;
      }, 500);
    }
  }, 500);
};
