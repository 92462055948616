import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryMobileMountedHandler = ({ detail }: EbizioEvent) => {
  logger('Cart Summary Mobile Mounted');
  console.log(detail);
};

export const cartSummaryMobileMountedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MOBILE_MOUNTED,
  handler: cartSummaryMobileMountedHandler,
};
