import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import {
  logger,
  onShippingLoadingOverlay,
  setActiveStepOnBody,
} from '../utils';
import { handleSavedAddress } from '../custom/handleSavedAddress';
import { savedShippingAddress } from '../custom/saved-shipping-address';
import { fillInShippingInformation } from '../custom/customer-group-shipping-address';

export const shippingStepLoadingHandler = async ({ detail }: EbizioEvent) => {
  logger('Shipping Step Loading');
  setActiveStepOnBody(detail.activeStepType);
  console.log(detail);

  if(detail.customer.addresses.length < 1) {
    fillInShippingInformation()
  }

  if (!detail.customer.isGuest) {
    handleSavedAddress();
  }

  // saved addresses
  // savedShippingAddress(detail.customer);

  // const saveAddressCheckbox = <HTMLInputElement>(
  //   document.getElementById('shippingAddress.shouldSaveAddress')
  // );

  // if (saveAddressCheckbox?.checked) {
  //   saveAddressCheckbox.click();
  //   // simulateClick('#shippingAddress.shouldSaveAddress');
  // }

  // Look for loading overlay, if added to the dom show our loading overlay
  onShippingLoadingOverlay(
    '.checkout-step--shipping .checkout-form',
    (isLoading) => {
      const FSO_FORM = document.getElementById(`fso-form`);
      const overlay = document.getElementById('fso-form-overlay');

      if (!FSO_FORM) return;
      if (isLoading) {
        FSO_FORM.classList.add('fso--reload-options');
        overlay?.classList.add('fso-form-overlay--show');
      } else {
        overlay?.classList.remove('fso-form-overlay--show');
      }
    },
  );
};

export const shippingStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_STEP_LOADING,
  handler: shippingStepLoadingHandler,
};
