import { inputValueSetter, selectValueSetter } from "../../utils";
import { fetcher } from "../../utils/api/request";

export const fillInShippingInformation = async () => {
    const {data: returnData} = await fetcher('api/get_shipping_information')

      const body = document.querySelector('body');

      if (body) {
          const firstNameTextBox = document.getElementById('firstNameInput');
          const lastNameTextBox = document.getElementById('lastNameInput');
          const companyNameTextBox = document.getElementById('companyInput');
          const phoneNumberTextBox = document.getElementById('phoneInput');
          const address1TextBox = document.getElementById('addressLine1Input');
          const address2TextBox = document.getElementById('addressLine2Input');
          const cityTextBox = document.getElementById('cityInput');
          const countryDropDown = document.getElementById('countryCodeInput');
          const stateDropDown = document.getElementById('provinceCodeInput');
          const stateTextBox = document.getElementById('provinceInput');
          const zipCodeTextBox = document.getElementById('postCodeInput');
          if (firstNameTextBox) {
              inputValueSetter(firstNameTextBox, returnData.first_name);
          }
          if (lastNameTextBox) {
              inputValueSetter(lastNameTextBox, returnData.last_name);
          }
          if (companyNameTextBox) {
              inputValueSetter(companyNameTextBox, returnData.company);
          }
          if (phoneNumberTextBox) {
              inputValueSetter(phoneNumberTextBox, returnData.phone);
          }
          if (address1TextBox) {
              inputValueSetter(address1TextBox, returnData.address1);
          }
          if (address2TextBox) {
              inputValueSetter(address2TextBox, returnData.address2);
          }
          if (cityTextBox) {
              inputValueSetter(cityTextBox, returnData.city);
          }
          if (countryDropDown) {
              selectValueSetter(countryDropDown, returnData.country.toUpperCase());
          }
          if (stateDropDown) {
                selectValueSetter(stateDropDown, returnData.state.toUpperCase());
          }
          if (stateTextBox) {
                inputValueSetter(stateTextBox, returnData.state);
            }
          if (zipCodeTextBox) {
            inputValueSetter(zipCodeTextBox, returnData.zip);
          }
        }
}