import { handleSavedAddress } from '../custom/handleSavedAddress';
import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import {
  logger,
  setActiveStepOnBody,
  simulateClick,
} from '../utils';

export const billingStepLoadingHandler = ({ detail }: EbizioEvent) => {
  logger('Billing Step Loading');
  setActiveStepOnBody(detail.activeStepType);
  console.log(detail);

  if (!detail.customer.isGuest) {
    handleSavedAddress();
  }

  const saveAddressCheckbox = <HTMLInputElement>(
    document.getElementById('shouldSaveAddress')
  );

  if (saveAddressCheckbox?.checked) {
    simulateClick('#shouldSaveAddress');
  }

  // saved addresses
};

export const billingStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.BILLING_STEP_LOADING,
  handler: billingStepLoadingHandler,
};
