import { checkboxValueSetter, setInputValue } from './set-input-value';
import { simulateClick } from './simulate-click';

type observerCallback = (isLoading: boolean) => void;

const DEFAULT_CONFIG = { attributes: true, childList: true, subtree: true };

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 */
const createObserver = (
  selector: string,
  options: any,
  callback: MutationCallback,
) => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector(selector);

  if (!targetNode) return;

  // Options for the observer (which mutations to observe)
  const config = { ...DEFAULT_CONFIG, ...options };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);
};

// Callback function to execute when mutations are observed
const exampleCallback = (
  mutations: MutationRecord[],
  observer: MutationObserver,
) => {
  // Use traditional 'for loops' for IE 11
  for (const mutation of mutations) {
    if (mutation.type === 'childList') {
      console.log('A child node has been added or removed.');
    } else if (mutation.type === 'attributes') {
      console.log('The ' + mutation.attributeName + ' attribute was modified.');
    }
  }
};

export const onShippingLoadingOverlay = (
  selector: string,
  isLoadingCallback: observerCallback,
) => {
  createObserver(selector, {}, (mutations) => {
    shippingLoadingOverly(mutations, isLoadingCallback);
  });
};

// export const onShippingStepLoaded = (
//   selector: string,
//   isLoadedCallback: observerCallback,
// ) => {
//   createObserver(selector, {}, (mutations) => {
//     mutations.forEach((mutation) => {
//       mutation.addedNodes.forEach((node) => {
//         const NODE = <HTMLElement | null>node;
//         const TARGET = <HTMLElement | null>mutation.target;
//         // shipping step loaded
//         if (NODE && TARGET && TARGET.classList.contains('checkout-form')) {
//           const addressToggle = NODE.querySelector('#addressToggle');
//         }
//       });
//       // console.log('mutation ', mutation);
//     });
//     // shippingAddressLoaded(mutations, isLoadedCallback);
//   });
// };

const shippingLoadingOverly = (
  mutations: MutationRecord[],
  callback: observerCallback,
) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((addedNode) => {
      const addedEl = <HTMLElement>addedNode;

      if (addedEl?.classList?.contains('loadingOverlay')) {
        callback(true);
      }
    });
    mutation.removedNodes.forEach((removedNode) => {
      const removedEl = <HTMLElement>removedNode;

      if (removedEl?.classList?.contains('loadingOverlay')) {
        callback(false);
      }
    });
  });
};

// const shippingAddressLoaded = (
//   mutations: MutationRecord[],
//   callback: observerCallback,
// ) => {
//   mutations.forEach((mutation) => {
//     mutation.addedNodes.forEach((addedNode) => {
//       const addedEl = <HTMLElement>addedNode;

//       if (addedEl.tagName !== 'DIV') return;
//       const addressToggle = <HTMLAnchorElement>(
//         addedEl.querySelector('#shippingAddresses #addressToggle')
//       );

//       if (addressToggle) {
//         console.log('addressToggle ', addressToggle);
//         // addressToggle.click();
//         const newAddressButton = <HTMLAnchorElement>(
//           addedEl.querySelector(
//             '#shippingAddresses [data-test="add-new-address"]',
//           )
//         );
//         console.log('newAddressButton ', newAddressButton);
//         // newAddressButton.click();
//         // const fsoForm = document.getElementById('fso-form');
//         // if (fsoForm) {
//         //   fsoForm.style.display = 'none';
//         // }
//       }
//     });
//     // const saveAddy = document.getElementById(
//     //   'shippingAddress.shouldSaveAddress',
//     // );
//     // if (saveAddy) {
//     //   checkboxValueSetter(saveAddy, false);
//     // }
//   });
// };
