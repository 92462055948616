import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';

export const customerStepLoadingHandler = ({ detail }: EbizioEvent) => {
  logger('Customer Step Loading');
  setActiveStepOnBody(detail.activeStepType);
  console.log(detail);
};

export const customerStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_STEP_LOADING,
  handler: customerStepLoadingHandler,
};
