import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';

export const shippingStepSubmittedHandler = ({ detail }: EbizioEvent) => {
  logger('Shipping Step Submitted');
  setActiveStepOnBody(detail.activeStepType);
  console.log(detail);
};

export const shippingStepSubmittedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_STEP_SUBMITTED,
  handler: shippingStepSubmittedHandler,
};
